import axios from "axios";
import { chefActions } from "./chefSlice";
import { API_LOCAL, API_TEST, EVENING_SHIFT_END_TIME, EVENING_SHIFT_START_TIME } from "./constants";

export const fetchChefList = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("chef");
      if (response.status != 200) {
        throw new Error("Could not fetch Chef list");
      }
      const data = response.data.data;
      return data;
    };

    try {
      const data = await fetchData();
      const now = new Date().getHours();
      let sortedData = [];
      if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
        sortedData = data.sort((a,b) => a.shift - b.shift || a.listRank - b.listRank);
      } else{
        sortedData = data.sort((a,b) => b.shift - a.shift || a.listRank - b.listRank);
      }
      dispatch(chefActions.replaceChefList({ chefList: sortedData }));
    } catch (error) {
      console.log("error while calling chef-fetchdata");
      // show error on ui
    }
  };
};

export const fetchCategories = (chefId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("menu/chef/categories/" + chefId);
      if (response.status != 200) {
        throw new Error("Could not fetch Chef list");
      }
      const data = response.data.data;
      return data;
    };

    try {
      const data = await fetchData();
      dispatch(
        chefActions.replaceCategories({
          categories: data.categories,
          chefId: chefId,
          id : data._id
        })
      );
    } catch (error) {
      console.log("error while calling categories-fetchdata");
      // show error on ui
    }
  };
};

