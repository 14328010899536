import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import Checkbox from "@material-ui/core/Checkbox";
import "./addressform.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressData } from "../../store/loginActions";

import Map from "./Map";
import Home from "@material-ui/icons/Home";
import { locationActions } from "../../store/locationSlice";
import {
  DELIVERY_CHARGE_PER_KMS,
  FREE_DILIVERY_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE_MIN,
} from "../../store/constants";
import { loginActions } from "../../store/loginSlice";
import { cartAction } from "../../store/cartSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const AddressForm = () => {
  const auth = useSelector((state) => state.auth);
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const selectedOrder = useSelector((state) => state.auth.selectedOrder);
  const delivery_distance = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_distance
  );
  const dispatch = useDispatch();
  const {
    user,
    location,
    coords,
  } = auth;

  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };

  useEffect(() => {
    mixpanel.track("add-address");
  }, []);

  const [isMapVisible, setIsMapVisible] = useState(location ? true : false);

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const onFocusHandler = () => {
    window.scroll({
      top: 200,
      left: 200,
      behavior: "smooth",
    });
    setIsMapVisible(false);
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    mixpanel.track("tab-changed-" + tabName);
  };

  const [tagState, setTagState] = useState({
    home: false,
    work: false,
    other: false,
  });

  const [other, setOther] = useState(false);

  const [error, setError] = useState(true);

  const [newAddress, setNewAddress] = useState({
    location: {
      locationName: location,
      lat: coords?.lat,
      lng: coords?.lng,
    },
    houseNumber: "",
    area: "",
    landMark: "",
    tag: "",
  });

  const errorHandler = () => {
    if (newAddress.houseNumber.trim().length && newAddress.tag.trim().length)
      setError(false);
    else setError(true);
  };

  useEffect(() => {
    errorHandler();
  }, [newAddress]);

  console.log(error);

  const { houseNumber, landMark, area } = newAddress;
  // const switchTab = (tabName) => {
  //   dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  // };

  const onBackClick = () => {
    selectedOrder?.bookedDishes ? switchTab("dishBook") : switchTab("cart");
  };

  const getDeliveryFee = () => {
    if (delivery_distance && isAreaServiceable) {
      let deliveryDistanceInKMS = Math.floor(delivery_distance / 1000);
      if (deliveryDistanceInKMS < FREE_DILIVERY_DISTANCE_RANGE) {
        return 0;
      } else {
        let fee = deliveryDistanceInKMS * DELIVERY_CHARGE_PER_KMS;
        return fee;
      }
    } else {
      return 0;
    }
  };
  const onAddAddressHandler = () => {
    if (selectedOrder?.bookedDishes) {
      let updatedOrder = {
        ...selectedOrder,
        address: newAddress,
        orderType: "order-now",
        deliveryFee: getDeliveryFee(),
      };
      selectedOrder?.bookedDishes &&
        dispatch(loginActions.setSelectedOrder(updatedOrder));
      dispatch(setAddressData(user.userid, newAddress, true));
    } else {
      dispatch(setAddressData(user.userid, newAddress, false));
    }

    const distanceValue = distance(
      sourceLocation.latitude,
      sourceLocation.longitude,
      coords.lat,
      coords.lng,
      "K"
    );
    if (distanceValue > ORDER_DISTANCE_RANGE) {
      dispatch(loginActions.setIsServiceable(false));
      dispatch(loginActions.setUnserviceableModalOnCart(true));
    } else if (distanceValue > ORDER_DISTANCE_RANGE_MIN && 
      distanceValue <= ORDER_DISTANCE_RANGE) {
      dispatch(loginActions.setIsServiceable(true));
      dispatch(loginActions.setFarAwayModalOnCart(true));
    } else {
      dispatch(loginActions.setIsServiceable(true));
    }
    dispatch(loginActions.setSelectedAddress(newAddress));
    dispatch(cartAction.setIsAddressOptionsVisible(false));

    setNewAddress({
      ...newAddress,
      houseNumber: "",
      landMark: "",
      area: "",
    });
    setTagState({
      home: false,
      work: false,
      other: false,
    });
    setOther(false);
  };
  // console.log(newAddress);

  return (
    <AppContainer>
      <div className="address-form_wrapper">
        <div className="address-form_top-bar">
          <MenuTopBar
            name="ADD ADDRESS"
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <div className="address-form">
          {isMapVisible ? <Map /> : null}
          <div className="address-form-container">
            <div className="address-form-input_container">
              <label>ADDRESS</label>
              <input
                onClick={() => {
                  switchTab("locationSelector");
                  dispatch(locationActions.setIsAddAddressActive(true));
                }}
                value={location}
                type="text"
                id="address-form-input"
              />
            </div>
            <div className="address-form-input_container">
              <label>HOUSE / FLAT NO</label>
              <input
                value={houseNumber}
                onChange={({ target: { value } }) =>
                  setNewAddress({ ...newAddress, houseNumber: value })
                }
                onFocus={() => onFocusHandler()}
                type="text"
                id="address-form-input"
              />
            </div>

            <div className="address-form-input_container">
              <label>AREA (optional)</label>
              <input
                value={area}
                onChange={({ target: { value } }) =>
                  setNewAddress({ ...newAddress, area: value })
                }
                type="text"
                id="address-form-input"
              />
            </div>

            <div className="address-form-input_container">
              <label>LANDMARK (optional)</label>
              <input
                value={landMark}
                onChange={({ target: { value } }) =>
                  setNewAddress({ ...newAddress, landMark: value })
                }
                type="text"
                id="address-form-input"
              />
            </div>

            {other ? (
              <div className="address-form-input_container">
                <label>Tag Name</label>
                <input
                  onChange={({ target: { value } }) =>
                    setNewAddress({ ...newAddress, tag: value })
                  }
                  type="text"
                  id="address-form-input"
                />
              </div>
            ) : (
              <div className="address-form-input_container">
                <label>TAG AS</label>
                <div className="tagas_container">
                  <button
                    onClick={() => {
                      setTagState({ work: false, other: false, home: true });
                      setNewAddress({ ...newAddress, tag: "home" });
                    }}
                    className={tagState.home ? "tag_active" : "tag"}
                  >
                    HOME
                  </button>
                  <button
                    onClick={() => {
                      setTagState({ work: true, other: false, home: false });
                      setNewAddress({ ...newAddress, tag: "work" });
                    }}
                    className={tagState.work ? "tag_active" : "tag"}
                  >
                    WORK
                  </button>
                  <button
                    onClick={() => {
                      setTagState({ work: false, other: true, home: false });
                      setNewAddress({ ...newAddress, tag: "" });
                      setOther(true);
                    }}
                    className={tagState.other ? "tag_active" : "tag"}
                  >
                    OTHER
                  </button>
                </div>
              </div>
            )}

            {isAreaServiceable ? (
             <>
              <div>
                  {
                  Math.round(delivery_distance / 1000) > FREE_DILIVERY_DISTANCE_RANGE ? 
                    (<p className="location_warning">
                    Your location is far from the chef's kitchen. <br/>
                    A delivery fee of ₹{(getDeliveryFee())} will be applicable.
                    </p>) : ""
                  }
                </div>
                <div className="actionBtn_container">
                  <button
                    disabled={error ? true : false}
                    onClick={() => onAddAddressHandler()}
                    className={`${
                      error
                        ? "disabled address-form_actionbtn"
                        : "address-form_actionbtn"
                    }`}
                  >
                    SAVE AND PROCEED
                  </button>
                </div>
              </>
            ) : (
              <p className="location_error_tag">
                Your location is not serviceable, Please select a serviceable
                location.
              </p>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default AddressForm;
