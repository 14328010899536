import MenuTopBar from "../menu/MenuTopBar";
import EarlyUserBenefits from "../userprofile/EarlyUserBenifits";
import "./banner-info.css";

const BannerInfo = () => {
  return (
    <div>
      <MenuTopBar name={"Go back"} />
      Banner info
      <div className="mt-32">
        <EarlyUserBenefits />
      </div>
    </div>
  );
};

export default BannerInfo;
