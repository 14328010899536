import React, { Suspense, useEffect, useState, useRef } from "react";
import ImageLoader from "../chef/ImageLoader";
import "../login/login.css";
import AppContainer from "../util/AppContainer";
import { SuspenseImg } from "../util/SuspendImg";
import mainImage from "../../assets/loginTheme.png";
import MenuTopBar from "../menu/MenuTopBar";
import { useDispatch, useSelector } from "react-redux";
import {v4 as uuidv4} from 'uuid';
import {
  checkout,
  confirmOrder,
  sendOTP,
  setAddressForPreOrder,
  updateOrder,
  getOpenPaymentToken,
} from "../../store/loginActions";
import bottomBarSlice, { bottomBarActions } from "../../store/bottomBarSlice";
import ReactGA from "react-ga4";
// Import React Scrit Libraray to load Google object
import Script from "react-load-script";
import {
  DELIVERY_CHARGE_PER_KMS,
  FREE_DILIVERY_DISTANCE_RANGE,
  ORDER_DISTANCE_RANGE,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
} from "../../store/constants";
import mixpanel from "mixpanel-browser";
import emailjs from "@emailjs/browser";
import { Checkbox } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { loginActions } from "../../store/loginSlice";
import Dish from "../menu/Dish";
import ReactPixel from 'react-facebook-pixel';

const CITY_NAME = "mumbai";
const TEMP_EMAIL = [
  // "null@cuirato.com",
  // "ashwin@cuirato.com",
  // "admin@cuirato.com",
  // "satish@cuirato.com",
];
const GST_RATE = 5;

const Checkout = (props) => {
  const form = useRef();
  const [query, setQuery] = useState("");
  console.log(query);
  const [city, setCity] = useState("");
  const [location, setLocation] = useState(null);
  const userLocation = useSelector((state) => state.auth.location);
  const coords = useSelector((state) => state.auth.coords);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const { cartData, totalCost } = useSelector(({ cart }) => cart);
  const [loginState, setLoginState] = useState({
    email: "",
    area: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const sourceLocation = {
    latitude: 19.116606,
    longitude: 72.8854982,
  };
  const delivery_distance = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_distance
  );
  const delivery_duration = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  );
  let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) + PREP_TIME : 0;
  // Store autocomplete object in a ref.
  // This is done because refs do not trigger a re-render when changed.
  const autocompleteRef = useRef(null);
  console.log(autocompleteRef);
  const selectedOrder = useSelector((state) => state.auth.selectedOrder);
  const openPaymentToken = useSelector((state) => state.auth.openPaymentToken);
  const [paymentMode, setPaymentMode] = useState("online");
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      console.log(addressObject);
      console.log(autocompleteRef.current.getPlace());
      let components = addressObject.address_components,
        // console.log(addressObject.name)
        // let components = addressObject.,

        city = "n/a";
      if (components) {
        for (let c = 0; c < components.length; ++c) {
          console.log(components[c].types.join("|"));
          if (
            components[c].types.indexOf("locality") > -1 &&
            components[c].types.indexOf("political") > -1
          ) {
            city = components[c].long_name;
            break;
          }
        }
      }
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
      }
      setCity(city);
      setQuery(
        addressObject
          ? `${addressObject.name},${addressObject.formatted_address}`
          : ""
      );
      console.log(addressObject);
    });
  };

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/checkout" });
    ReactGA.event({
      category: "tab",
      action: "checkout",
    });
  }, []);

  useEffect(() => {
    if (openPaymentToken) {
      window.triggerLayer(openPaymentToken);
    }
  }, [openPaymentToken]);

  const getTotal = () => {
    let total = 0;
    selectedOrder.bookedDishes.forEach(dish => {
      total = total + (dish.isAddedOnDiscount? dish.dealPrice : dish.price);
    });
    return total;
  }

  const getDeliveryFee = (schedule) => {
    if (delivery_distance && isAreaServiceable) {
      let deliveryDistanceInKMS = Math.floor(delivery_distance / 1000);
      if (deliveryDistanceInKMS < FREE_DILIVERY_DISTANCE_RANGE) {
        return 0;
      } else {
        let fee = deliveryDistanceInKMS * DELIVERY_CHARGE_PER_KMS;
        if(!schedule){
          if(cartData.length > user.maxDishes){
            cartData.forEach(dish => {
              if(!dish.isAddedOnDiscount){
                fee = fee - dish.deliveryCost;
              }
            });
          }
        }
        return fee > 0 ? fee : 0;
      }
    } else {
      return 0;
    }
  };
  const getGSTAmount = (total) => {
    // Calculate 5% GST
    return (parseInt(total) * GST_RATE) / 100;
  };

  const getPackagingAmount = (schedule) => {
    let packagingFee = 0;
    if(schedule){
      selectedOrder.bookedDishes.forEach(cart => {
        if(cart.isAddedOnDiscount){
          packagingFee = packagingFee + cart.dish.packagingCost;
        }
      });
    }else{
      cartData.forEach(cart => {
        if(cart.isAddedOnDiscount){
          packagingFee = packagingFee + cart.packagingCost;
        }
      });
    }
    return packagingFee;
  };  
  
  const sendEmail = (e) => {
    const mobileNumber = "91" + user.phonenumber;
    dispatch(confirmOrder({ mobileNumber }));
    console.log("Order sucessfull sms sent");
    emailjs
      .sendForm(
        "service_3t471gh", // replace with your service id
        "template_1d10sj4", // replace with your template id
        form.current,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log("Order sucessfull email sent");
  };

  const checkoutCart = (bookForLater) => {
    mixpanel.track("checked-out");
    if (loginState.email) {
      setErrorMessageForDelivery("");
      setError(false);
      // const distanceValue = distance(
      //   sourceLocation.latitude,
      //   sourceLocation.longitude,
      //   location.lat,
      //   location.lng,
      //   "K"
      // );
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginState.email)
      ) {
        setError(true);
        setErrorText("Email id is not valid");
        return;
      }
      // if (city.toLowerCase() !== CITY_NAME) {
      //   setErrorMessageForDelivery("Available only in Mumbai");
      //   return;
      // }
      // if (!bookForLater && distanceValue > ORDER_DISTANCE_RANGE) {
      //   setErrorMessageForDelivery(
      //     "Delivery area should be inside 12km from the kitchen in Andheri west. You can choose to book later and get delivery when service is available in your area."
      //   );
      //   return;
      // }

      let newAddress = {
        area: "",
        houseNumber: "",
        landMark: "",
        location: {
          lat: coords.lat,
          lng: coords.lng,
          locationName: userLocation,
        },
        tag: "default",
      };
      dispatch(setAddressForPreOrder(user.userid, newAddress));
      dispatch(loginActions.setSelectedAddress(newAddress));
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      let deliveryFee = getDeliveryFee();
      let packagingFee = getPackagingAmount(false);
      let gstFee = getGSTAmount(totalCost);
      let billingAmount = Math.round(totalCost + gstFee + deliveryFee + packagingFee);
      dispatch(
        checkout(
          cartData,
          user,
          loginState.email,
          newAddress,
          orderType,
          deliveryFee,
          delivery_distance,
          delivery_Time,
          totalCost,
          packagingFee,
          gstFee,
          billingAmount,
          null,
          null
        )
      );
      sendEmail();
      ReactPixel.track("Purchase", {value: billingAmount, currency: "INR"});
    } else {
      if (!loginState.email) {
        setError(true);
        setErrorText("Email id is required");
        return;
      } else if (!loginState.area) {
        setError(true);
        setErrorText("Address is required");
        return;
      }
    }
  };

  const sendEmailOnConfirm = (billingAmount) => {
    let dishBookedName = "";
    cartData.forEach((data) => {
      dishBookedName += data.name + ", ";
    });

    const { username, phonenumber } = user;

    const mobileNumber = "91" + phonenumber;
    let templateParams = {
      user_name: username,
      phone_number: mobileNumber,
      dish_name: dishBookedName,
      area: selectedAddress.area,
      houseNumber: selectedAddress.houseNumber,
      landmark: selectedAddress.landMark,
      locationName: selectedAddress.location.locationName,
      billingAmount: billingAmount,
      deliveryTime: delivery_Time,
      lat: selectedAddress.location.lat,
      lng: selectedAddress.location.lng,
      tag: selectedAddress.tag,
      to_email: TEMP_EMAIL,
    };
    dispatch(confirmOrder({ mobileNumber }));
    emailjs
      .send(
        "service_3t471gh", // replace with your service id
        "template_3x8ijk9", // replace with your template id
        templateParams,
        "kWiFDOBeK7Oc_Y7Jg" // replace with your public key
      )
      .then((res) => console.log(res.text))
      .catch((error) => console.log(error));
  };

  const processOrder = (paymentId) => {
    const orderType = isAreaServiceable ? "order-now" : "pre-launch";
    let deliveryFee = getDeliveryFee();
    let packagingFee = getPackagingAmount(false);
    let gstFee = getGSTAmount(totalCost);
    let billingAmount = Math.round(totalCost + gstFee + deliveryFee + packagingFee);
    selectedOrder?.bookedDishes
      ? dispatch(updateOrder(selectedOrder, getDeliveryFee(true)))
      : dispatch(
          checkout(
            cartData,
            user,
            "",
            selectedAddress,
            orderType,
            deliveryFee,
            delivery_distance,
            delivery_Time,
            totalCost,
            packagingFee,
            gstFee,
            billingAmount,
            paymentMode,
            paymentId
          )
        );
      sendEmailOnConfirm(billingAmount);
      mixpanel.track("checked-out");
      ReactPixel.track("Purchase", {value: billingAmount, currency: "INR"});
  }

  window.onPaymentResponse = (response) => {
    if(response.status == 'cancelled' || response.status == 'failed'){
      dispatch(loginActions.setOpenPaymentToken(null));
      setConfirmDisabled(false);
    }else{
      processOrder(response.payment_id);
      dispatch(loginActions.setOpenPaymentToken(null));
      setConfirmDisabled(false);
    }
  };

  const onConfirmHandler = () => {
    setConfirmDisabled(true);
    let deliveryFee = getDeliveryFee();
    let packagingFee = getPackagingAmount(false);
    let gstFee = getGSTAmount(totalCost);
    let billingAmount = Math.round(totalCost + gstFee + deliveryFee + packagingFee);
    switch(paymentMode){
      case "online":
        const paymentId = uuidv4();
        dispatch(getOpenPaymentToken(paymentId, billingAmount, user.phonenumber));
        break;
      case "cash":
        processOrder();
        break;
      case "upi":
        processOrder();
        break;
    }
  };

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  // This function calculate the distance between two end point
  const distance = (
    sourceLat,
    sourceLon,
    destinationLat,
    destinationLon,
    unit
  ) => {
    if (sourceLat == destinationLat && sourceLon == destinationLon) {
      return 0;
    } else {
      var radlat1 = (Math.PI * sourceLat) / 180;
      var radlat2 = (Math.PI * destinationLat) / 180;
      var theta = sourceLon - destinationLon;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  };

  const onBackClick = () => {
    selectedOrder?.bookedDishes ? switchTab("dishBook") : switchTab("cart");
  };

  const [isChecked, setIsChecked] = useState(false);

  return (
    <AppContainer id={props.id}>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={handleScriptLoad}
      />
      <div className="cart-top-bar">
        <MenuTopBar
          name="Checkout"
          cuisine={isAreaServiceable ? "Deliver now" : "Pre-order"}
          notMenu={true}
          onBackClick={onBackClick}
        />
      </div>
      <form
        className="login-container checkout-container"
        ref={form}
        onSubmit={(e) => e.preventDefault()}
      >
        <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
          <div className="mt">
            <SuspenseImg src={mainImage}></SuspenseImg>{" "}
          </div>
        </Suspense>

        {isAreaServiceable && selectedOrder ? (
          <>
            <div className="food-accordian">
              <p className="cart-header">Order Details</p>
              <div className="dish-wrapper-list">
                {selectedOrder.bookedDishes.map((dish, i) => {
                  return (
                    <div className="price-box">
                      <div className="items-price items dishes">
                        <div className="price-title">{dish.dish.name}</div>
                        <div className="price-value">
                        {parseInt(dish.isAddedOnDiscount? dish.dealPrice : dish.price)}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="billing-section mx-2">
                  <div className="billing-title">Billing Details</div>
                  <div className="price-box">
                    <div className="items-price items__first">
                      <div className="price-title">Items Price</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {parseInt(selectedOrder.totalCost)}{" "}
                      </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">Delivery Fees</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {getDeliveryFee(true)}{" "}
                      </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">Packaging</div>
                      <div className="price-value"> &#8377; {selectedOrder.packagingFee} </div>
                    </div>
                    <div className="items-price items">
                      <div className="price-title">GST</div>
                      <div className="price-value">
                        {" "}
                        &#8377; {selectedOrder.gstFee}{" "}
                      </div>
                    </div>
                    <div className="items-price items__total">
                      <div className="price-title">Total</div>
                      <div className="price-value">
                        {" "}
                        &#8377;{" "}
                        {Math.round(
                          selectedOrder.totalCost + selectedOrder.gstFee + getDeliveryFee(true) + selectedOrder.packagingFee
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payon_delivery-container">
              <h6> UPI on delivery</h6>
              <input type="checkbox" checked={paymentMode === "upi"} value="upi" onChange={() => setPaymentMode("upi")} />
            </div>
            <div className="payon_delivery-container">
              <h6> Cash on delivery</h6>
              <input type="checkbox" checked={paymentMode === "cash"} value="cash" onChange={() => setPaymentMode("cash")} />
            </div>
            <div className="payon_delivery-container">
              <h6> Pay online</h6>
              <input type="checkbox" checked={paymentMode === "online"} value="online" onChange={() => setPaymentMode("online")} />              
            </div>
            <div className="payon_delivery-container">
              <p className="pay-info">Secure transaction via Open Money</p>
            </div>
          </>
        ) : isAreaServiceable ? (
          <>
            <div className="payon_delivery-container">
              <h6> UPI on delivery</h6>
              <input type="checkbox" checked={paymentMode === "upi"} value="upi" onChange={() => setPaymentMode("upi")} />
            </div>
            <div className="payon_delivery-container">
              <h6> Cash on delivery</h6>
              <input type="checkbox" checked={paymentMode === "cash"} value="cash" onChange={() => setPaymentMode("cash")} />
            </div>
            <div className="payon_delivery-container">
              <h6> Pay online</h6>
              <input type="checkbox" checked={paymentMode === "online"} value="online" onChange={() => setPaymentMode("online")} />              
            </div>
            <div className="payon_delivery-container">
              <p className="pay-info">Secure transaction via Open Money</p>
            </div>
          </>
        ) : (
          <>
            <div className="logo-container">
              <h5>Checkout</h5>
              <p>
                We will update you when your preferred chefs go live. You will
                be able schedule your delivery through the app.
              </p>
            </div>
            <div className={error ? "" : "hide"}>
              <p className="error-msg">{errorText}</p>
            </div>
            <label htmlFor="email">
              Email
              <input
                id="email"
                type="email"
                className="login-phone input-text"
                name="email"
                onChange={({ target }) => {
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setError("");
                  setErrorText("");
                }}
                placeholder="Enter your email"
                value={loginState.email}
              />
            </label>

            {/* <label htmlFor="searchAutoComplete">
              Address
              <input
                id="searchAutoComplete"
                type="text"
                className="login-phone"
                name="area"
                onChange={({ target }) => {
                  setQuery("");
                  setLoginState({
                    ...loginState,
                    [target.name]: target.value,
                  });
                  setErrorMessageForDelivery("");
                }}
                placeholder="Enter your address"
                value={query ? query : loginState.area}
              />
            </label> */}
          </>
        )}

        {errorMessageForDelivery && (
          <p className="checkout-error-msg"> {errorMessageForDelivery} </p>
        )}
        <div className="checkout-option active" id="cart_option">
          {isAreaServiceable ? (
            <button
              type="submit"
              className="bottom-button"
              disabled={confirmDisabled}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              CONFIRM
            </button>
          ) : (
            <button
              type="submit"
              disabled={loginState.email === "" ? true : false}
              onClick={() => checkoutCart(errorMessageForDelivery && true)}
              className={`bottom-button ${
                loginState.email === "" ? `disabled` : ""
              }`}
            >
              {errorMessageForDelivery ? "Book for later" : "Book"}
            </button>
          )}
        </div>
      </form>
    </AppContainer>
  );
};

export default Checkout;
