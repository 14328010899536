import { useSelector } from "react-redux";
import AppContainer from "../util/AppContainer";
import "./locationSelector.css";
import GPSLocator from "./GPSLocator";
import RecentSearches from "./RecentSearches";
import LocationSearch from "./LocationSearch";

const LocationSelector = (props) => {
  const isAddAddressActive = useSelector(
    (state) => state.location.isAddAddressActive
  );

  return (
    <AppContainer>
      <LocationSearch from={props.from} />
      <GPSLocator from={props.from} />
      {!isAddAddressActive && <RecentSearches from={props.from} />}
      {/* <div className="download-pwa-btn">
        <button>Download PWA</button>
      </div> */}
    </AppContainer>
  );
};

export default LocationSelector;
