import { BenefitsSkims } from "./styled";

const EarlyUserBenefits = () => {
  return (
    <BenefitsSkims>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>Access the first 8 chefs before everyone else.</p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>Explore menus of up to 4 chefs of your choice.</p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>Check discounted dish prices of up to 10 dishes.</p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>Pre-order up to 2 dishes.</p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>
          Discounts range from 40-99% depending on the popularity of the chef
          and dish.
        </p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>
          Get your own referral code. For every friend you refer, you get to:
        </p>
      </div>
      <div className="SkimItem">
        <img src="./images/true.png" alt="" />
        <p>
          Pre-order 1 more dish. <br />
          Explore menu of 1 more chef. <br />
          Check discounted prices of up to 5 more dishes.
        </p>
      </div>
    </BenefitsSkims>
  );
};
 export default EarlyUserBenefits;