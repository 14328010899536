import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import App from "./App";
import store from "./store/index"

import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import ReactPWAInstallProvider from "react-pwa-install";
import { API_TEST } from "./store/constants";
import ErrorBoundary from "./utils/ErrorBoundary";

axios.defaults.baseURL = API_TEST;

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
    <ReactPWAInstallProvider enableLogging>
        <App />
        </ReactPWAInstallProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

window.addEventListener('load', function() {
  window.history.pushState({ noBackExitsApp: true }, '')
});

window.addEventListener('popstate', function(event) {
  if (event.state && event.state.noBackExitsApp) {
    window.history.pushState({ noBackExitsApp: true }, '')
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
