const DishCheckPrice = ({
    showAdd, setShowAdd, showAdded, setShowAdded, showSchedule, onAddDish, onRemoveDish, updateDish, showOneDishValidation, dishBand, dish, menuCartData
}) => {
    const totalCartAddedDishCount = menuCartData?.filter((order) => order.dishId === dish.id)?.length;
    
    return (
        <div className="dish-add-wrapper">
            {!showAdd &&
            !showAdded &&
            !showSchedule &&
            !dish.unAvailable ? (
            <button
                type="button"
                className={
                dish.img
                    ? "btn btn-add check-price-btn"
                    : "btn btn-add check-price-btn bottom1"
                }
                onClick={() =>
                updateDish(
                    dish.id,
                    setShowAdd,
                    dish.price,
                    dish.dish.ingredientCost,
                    dish.dish.fuelCost,
                    dishBand.band
                )
                }
            >
                <span>Check Price</span>
                <img
                src="images/Icons/Icon-06.svg"
                alt="search-item"
                loading="lazy"
                />
            </button>
            ) : (
            ""
            )}
            {showAdd && !showSchedule && !dish.unAvailable ? (
            <button
                type="button"
                className={
                dish.img ? "btn btn-add" : "btn btn-add bottom1"
                }
                onClick={() => onAddDish(dish.id)}
            >
                <span>Add to Cart</span>
                <img
                src="images/icon-plus.svg"
                alt="search-item"
                loading="lazy"
                />
            </button>
            ) : (
            ""
            )}

            {dish.unAvailable && !showSchedule && (
            <button type="button" className="btn btn-add">
                <span>unavailable</span>
                <img
                src="images/access-denied-icon.svg"
                alt="search-item"
                loading="lazy"
                />
            </button>
            )}

            {showAdded && !showSchedule && !dish.unAvailable ? (
            <div
                className={
                dish.img
                    ? "input-group add-items"
                    : "input-group add-items bottom1 ml-2"
                }
            >
                <button
                className="btn btn-minus-item"
                onClick={() =>
                    onRemoveDish(
                    dish.id,
                    setShowAdd,
                    setShowAdded,
                    dish.price,
                    dish.dish.ingredientCost,
                    dishBand.band,
                    totalCartAddedDishCount
                    )
                }
                >
                <img
                    src="images/icon-minus.svg"
                    alt="search-item"
                    loading="lazy"
                />
                </button>
                <div>{totalCartAddedDishCount}</div>
                <button
                className="btn btn-add-item"
                onClick={() => onAddDish(dish.id)}
                >
                <img
                    src="images/icon-plus.svg"
                    alt="search-item"
                    loading="lazy"
                />
                </button>
            </div>
            ) : (
            ""
            )}
            {showSchedule && dish.isScheduleActive ? (
            <div className="input-group add-items item-schedule">
                <button
                type="button"
                className="btn btn-added btn-schedule"
                >
                <span> Schedule </span>
                </button>
                <img src="images/time-period.svg" />
            </div>
            ) : (
            ""
            )}
        </div>
    );
}

export default DishCheckPrice;