import { useDispatch } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import { dateFormatter, timeFormatter } from "../util/DateTimeFormatter";
import "./order-history.css";

const GST_RATE = 5;

const OrderHistory = ({ data, isSchedule }) => {
  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const { createdAt } = data;
  let orderedDate = new Date(createdAt);
  let totalPrice = 0;
  let orderedDishes = "";
  let chefDetails = "";
  data.bookedDishes.forEach((dishes, i) => {
    dishes.isAddedOnDiscount || data.preOrdered
      ? (totalPrice += dishes.dealPrice)
      : (totalPrice += dishes.price);
    if (data.bookedDishes.length == 1 || i == data.bookedDishes.length - 1) {
      if (dishes.dish) {
        orderedDishes += dishes.dish.name;
        if (!chefDetails.includes(dishes.dish.chefName)) {
          chefDetails += "Chef " + dishes.dish.chefName + ".";
        }
      }
    } else {
      if (dishes.dish) {
        orderedDishes += dishes.dish.name + ", ";
        if (!chefDetails.includes(dishes.dish.chefName)) {
          chefDetails += "Chef " + dishes.dish.chefName + ", ";
        }
      }
    }
  });

  return (
    <div className="order-history_container">
      <div className="bd-top-section">
        <div className="order-history_left">
          <h1>{chefDetails}</h1>
          <p>{data.address?.location?.locationName}</p>
          <p>
            {dateFormatter.format(orderedDate)},{" "}
            {timeFormatter.format(orderedDate)}
          </p>
        </div>
        {(data.orderType == "pre-launch") && (
          <div
            className="order-history_schedule"
            onClick={() => {
              switchTab("addressForm");
              dispatch(loginActions.setSelectedOrder(data));
            }}
          >
            <img src="images/time-period.svg" />
            <button type="button" className="order-history_btn-schedule">
              Schedule
            </button>
          </div>
        )}
      </div>
      <div className="bd-bottom-section">
        <p>{orderedDishes}</p>
        <p>
          ₹
          {data.billingAmount}
        </p>
      </div>
    </div>
  );
};

export default OrderHistory;
