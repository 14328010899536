import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGeoLocation from "../../hooks/useGeoLocation";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import AppContainer from "../util/AppContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./location.css";

const Locator = () => {
  const dispatch = useDispatch();

  const isLocationEnabled = useSelector(({ auth }) => auth.isLocationEnabled);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const setLocation = () => {
    if (isLocationEnabled) switchTab("chef");
    else {
      switchTab("locationSelector");
    }
  };

  useEffect(() => {
    setLocation();
  }, []);

  return (
    <AppContainer>
      <div className="location_btn-container" 
       onClick={() => {
        switchTab("locationSelector");
      }}
      >
        <button
          onClick={() => {
            switchTab("locationSelector");
          }}
        >
          Setup Your Location
        </button>
      </div>
      <ToastContainer limit={1} />
    </AppContainer>
  );
};

export default Locator;
