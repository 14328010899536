import { createSlice } from "@reduxjs/toolkit";
import { getUniqueArray } from "../utils/helper";

const initialState = {
  cartData: [],
  menuCartData: [],
  error: false,
  isLoading: false,
  totalCost: 0,
  isAddressOptionsVisible: true,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      state.isLoading = action.payload.isLoading;
      state.cartData = action.payload.cartData;
      return state;
    },
    setMenuCart(state, action) {
      state.isLoading = action.payload.isLoading;
      state.menuCartData = action.payload.cartData;
      if (state.menuCartData.length > 0) {
        let totalCost = 0;
        state.menuCartData.forEach((cart) => {
          cart.isAddedOnDiscount
            ? (totalCost += cart.dealPrice)
            : (totalCost += cart.price);
        });
        state.totalCost = totalCost;
      }
      return state;
    },
    addCartData(state, action) {
      let cartDataFromPayload = {
        ...action.payload.cartData,
        isAddedOnDiscount: action.payload.cartPayload.isAddedOnDiscount,
      };
      state.cartData = getUniqueArray([...state.cartData, cartDataFromPayload]) ;
      state.menuCartData = [...state.menuCartData, action.payload.cartPayload];
      state.isLoading = action.payload.isLoading;
      // state.totalCost = state.totalCost + action.payload.cartPayload.dealPrice;
      state.totalCost +=
        action.payload.maxDishes !== 0 && cartDataFromPayload.isAddedOnDiscount
          ? action.payload.cartPayload.dealPrice
          : action.payload.cartData.price;
      return state;
    },
    removeCardData(state, action) {
      let dealPrice = 0;
      let price = 0;
      let isAddedOnDiscount;
      state.isLoading = action.payload.isLoading;
      const cartIndex = state.cartData.findIndex(cart => cart._id === action.payload.dishId);
      const countTotalOrder = state.menuCartData.filter(cart => cart.dishId === action.payload.dishId).length;
      state.cartData.forEach((cart, index) => {
        if (cart._id === action.payload.dishId) {
          if(cartIndex === index && countTotalOrder === 1){
            state.cartData.splice(index, 1);
          }
          price = cart.price;
          isAddedOnDiscount = cart.isAddedOnDiscount;
        }
      });
      const menuCartIndex = state.menuCartData.findIndex(cart => cart.dishId === action.payload.dishId);
      state.menuCartData.forEach((cart, index) => {
        if (cart.dishId === action.payload.dishId) {
          if(menuCartIndex === index){
            state.menuCartData.splice(index, 1);
          }
          dealPrice = cart.dealPrice;
        }
      });

      state.totalCost -=
        isAddedOnDiscount && action.payload.maxDishes !== 0 ? dealPrice : price;

      return state;
    },
    emptyCartData(state, action) {
      state.isLoading = action.payload.isLoading;
      state.cartData = [];
      state.menuCartData = [];
      state.totalCost = 0;
      return state;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload.isLoading;
      state.cartData = [];
      return state;
    },
    setTotalCost(state, action) {
      state.cartData.forEach((cart) => {
        if (cart._id == action.payload.dishId) {
          const countTotalOrder = state.menuCartData.filter(cart => cart.dishId === action.payload.dishId).length;
          cart.dealPrice = action.payload.dealPrice;
          action.payload.maxDishes !== 0 && cart.isAddedOnDiscount
            ? (state.totalCost += (cart.dealPrice * countTotalOrder))
            : (state.totalCost += cart.price * countTotalOrder);
        }
        // let totalCost = 0;
        // state.cartData.forEach((cart) => {
        //   action.payload.maxDishes !== 0 && cart.isAddedOnDiscount
        //     ? (totalCost += cart.dealPrice)
        //     : (totalCost += cart.price);
        // });
        // state.totalCost = totalCost;
      });
      return state;
    },
    calculateTotalCost(state) {
      let totalCost;
      state.cartData.forEach((cart) => {
        totalCost = state.totalCost + cart.dealPrice;
      });
      state.totalCost = totalCost;
      return state;
    },
    removeDishCost(state, action) {
      state.totalCost = state.totalCost - action.payload.dealPrice;
      return state;
    },
    resetTotalCost(state, action) {
      state.totalCost = 0;
      return state;
    },
    setIsAddressOptionsVisible(state, { payload }) {
      state.isAddressOptionsVisible = payload;
    },
  },
});

export const cartAction = cartSlice.actions;

export default cartSlice;
