import React, { Suspense } from "react";
import "./banner-wrapper.css";
import ChefCardLoader from "../chef/ChefCardLoader";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { useDispatch } from "react-redux";

const BannerWrapper = (props) => {
    const dispatch = useDispatch();

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  return (
    <section className="banner">
      <div className="banner-wrapper active" id="banner_wrapper">
        {/* <Suspense fallback={console.log("loading")}> */}
        <div className="banner-slider" 
        // onClick={() => {
        //   switchTab("bannerInfo")
        // }}
        >{props.children}</div>
        {/* </Suspense> */}
      </div>

    </section>
  );
};

export default BannerWrapper;
