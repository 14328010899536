import React, { useEffect, useLayoutEffect, useState } from "react";
import "./menu-categories.css";
import "../base.css";
import Dish from "./Dish";
import { Collapse } from "react-collapse";
import { Accessible } from "./Accesible";
import { useDispatch, useSelector } from "react-redux";
import { cartAction } from "../../store/cartSlice";
import mixpanel from "mixpanel-browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDidMountEffect from "../../hooks/SkipInitialRender";
import {
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
} from "../../store/constants";

const Category = (props) => {
  const dispatch = useDispatch();
  const tilesClicked = useSelector((state) => state.dish.tilesClicked);
  const dishesTab = useSelector((state) => state.bottomBar.dishes);
  const user = useSelector(({ auth }) => auth.user);
  const freeMenuidsOpened = useSelector(
    (state) => state.auth.freeMenuidsOpened
  );
  const menuIds = user.phonenumber ? user.menuIdsOpened : freeMenuidsOpened;

  let [isOpen, setIsOpen] = useState(false);
  let [locked, setLocked] = useState(false);

  let displayCart = useSelector((state) => state.bottomBar.cart);

  const dishes = props.dishes;
  const toggleCategory = () => {
    isOpen = setIsOpen(!isOpen);
    if (!isOpen) {
      mixpanel.track("menu-category-opened");
    }
  };

  useDidMountEffect(() => {
    setIsOpen(!isOpen);
  }, [displayCart]);

  // useEffect(() => {
  //   setLocked(props.menuId ? !menuIds.includes(props.menuId) : false);
  // });

  // useLayoutEffect(() => {
  //   if (!isOpen) {
  //     dispatch(cartAction.resetTotalCost());
  //   }
  // }, [dispatch, isOpen]);
  const lockedUnlockedNotify = () =>
    toast.info("Unlock the chef's menu to explore", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "info1",
    });
  let isPriceBandVisible =
    user.phonenumber ? user.maxDishes == 2 && user.totalDishesBooked < user.maxDishes : true;
  return (
    <Collapse isOpened={true}>
      {isOpen ? (
        <li className="collapsible-item active" id={props.categoryid}>
          <div
            className="collapsible-header"
            onClick={() => toggleCategory(props.categoryid)}
          >
            <div className="title-dish">
              <h5>
                {dishesTab && tilesClicked
                  ? props.cuisine +
                    " - " +
                    props.name +
                    " by Chef " +
                    props.dishes[0].chefName
                  : props.name}
              </h5>
              <p className="count-item">
                <span className="num">{props.dishes.length}</span>items
              </p>
            </div>
            <div className="icon-down-div">
              {/* {dishesTab && tilesClicked && (
                <img
                  className="menu-locked-unlocked"
                  src={
                    locked
                      ? "images/Icons/Icon-04.svg"
                      : "images/Icons/Icon-05.svg"
                  }
                  alt=""
                />
              )} */}
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
          <div className="collapsible-body">
            <div className="dish-wrapper-list">
              {dishes.map((dish, i) => (
                <Dish
                  key={i}
                  id={dish._id}
                  name={dish.name}
                  category={props.category}
                  price={dish.price}
                  type={dish.type}
                  img={dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""}
                  mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                  description={dish.description}
                  saff={props.saff}
                  dish={dish}
                  onCheckPrice={props.onCheckPrice}
                  calculateTotal={false}
                  isFromCart={false}
                  setShowAddedItemPopup={props.setShowAddedItemPopup}
                  parentComponent={props.parentComponent}
                  isScheduleActive={true}
                  unAvailable={dish.unavailable}
                  isPriceBandVisible={isPriceBandVisible}
                  isAddedOnDiscount={true}
                  isFromOrderSummary ={false}
                />
              ))}
            </div>
          </div>
        </li>
      ) : (
        <li
          className="collapsible-item"
          id={props.categoryid}
          onClick={() =>
            locked ? lockedUnlockedNotify() : toggleCategory(props.categoryid)
          }
        >
          <div className="collapsible-header">
            <div
              className="title-dish"
              // onClick={!locked && lockedUnlockedNotify}
            >
              <h5>
                {dishesTab && tilesClicked
                  ? props.cuisine +
                    " - " +
                    props.name +
                    " by Chef " +
                    props.dishes[0].chefName
                  : props.name}
              </h5>
              <p className="count-item">
                <span className="num">{props.dishes.length}</span>items
              </p>
            </div>
            <div className="icon-down-div">
              {/* {dishesTab && tilesClicked && (
                <img
                  className="menu-locked-unlocked"
                  src={
                    locked
                      ? "images/Icons/Icon-04.svg"
                      : "images/Icons/Icon-05.svg"
                  }
                  alt=""
                />
              )} */}
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
        </li>
      )}
      {/* toast */}
      <ToastContainer limit={1} />
    </Collapse>
  );
};

export default Category;
