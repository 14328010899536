import "./chef-cards.css";
import "../base.css";
import React from "react";
import { useDispatch } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import mixpanel from "mixpanel-browser";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { menuActions } from "../../store/menuSlice";

const MenuButton = (props) => {
  const dispatch = useDispatch();
  const onMenuClick = () => {
    if (props.isMenuActive) {
      dispatch(menuActions.setIsMenuClicked(true));
      dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
      dispatch(chefActions.onMenuClick(props.chefId));
    }
    mixpanel.track("explore-menu-clicked", {
      chefId: props.chefId,
    });
  };

  return (
    <div className="menu-wrapper">
      <button onClick={() => onMenuClick()} className="btn btn-menu">
        <figure>
          <img src={props.img} alt={props.alt} />
        </figure>
        <span>{props.title}</span>
      </button>
    </div>
  );
};

export default MenuButton;
