import EarlyUserBenefits from "./EarlyUserBenifits";
import { EarlyUsersBoxWrapper, BenefitsSkims } from "./styled";

const EarlyUsers = (props) => {
  return (
    <EarlyUsersBoxWrapper>
      {/* <div className="BenefitsSkimsTitle">
        <p>Pricing Strategy</p>
      </div>
      <BenefitsSkims>
        <div className="SkimItem">
          <img src="./images/true.png" alt="" />
          <p>
            Our top 8 chefs are introducing their toothsome menus with the
            'First Come-Buy Best' deals for Early Access Users just like you.
          </p>
        </div>
        <div className="SkimItem">
          <img src="./images/true.png" alt="" />
          <p>
            The chefs are at high availability now. With the shooting demand,
            our chefs will get busier, leading to a surge in the dish price.
          </p>
        </div>
        <div className="SkimItem">
          <img src="./images/true.png" alt="" />
          <p>
            Cuirato considers chef's availability as the key commodity factoring
            in the pricing strategy.
            <br />
            Start Prebooking Now because now is the best time!
          </p>
        </div>
      </BenefitsSkims> */}
      <div className="BenefitsSkimsTitle">
        <p>
          Early user benefits
          {/* {props.notLoggedIn
            ? "Privileges after Sign up:"
            : "A token of thanks to all you lovely Early Users from Team Cuirato."} */}
        </p>
      </div>
      <EarlyUserBenefits />
      {/* <div className="BenefitsSkimsTitle">
        <p>Privileges without signup:</p>
      </div>
      <BenefitsSkims>
        <div className="SkimItem">
          <img src="./images/true.png" alt="" />
          <p>Explore the menu of up to 2 Chefs.</p>
        </div>
      </BenefitsSkims> */}
    </EarlyUsersBoxWrapper>
  );
};

export default EarlyUsers;
