import React, { useState } from "react";
import "./location-selection.css";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
// import useGeoLocation from "../../hooks/useGeoLocation";

const LocationSelection = () => {
  // const city = useGeoLocation();
  const dispatch = useDispatch();

  const switchTab = (event) => {
    // event.preventDefault();
    console.log("loc-click");
    dispatch(bottomBarActions.loadTab({ tabName: "locationSelector" }));
    mixpanel.track("location-clicked");
    return false;
  };

  const location = useSelector(({ auth }) => auth.location);
  const selectedAddress = useSelector(({ auth }) => auth.selectedAddress);

  return (
    <li
      onClick={() => switchTab("locationSelector")}
      className="select-location"
    >
      {selectedAddress?.tag ? (
        <div className="form-select">{selectedAddress.tag}</div>
      ) : (
        <div className="form-select">
          {location
            ? location.length >= 15
              ? location.slice(0, 15) + "..."
              : location
            : "Mumbai"}
        </div>
      )}
      {/* <select
        className="form-select"
        aria-label="Default select example"
        onClick="return false"//(e) => setName(e.target.value)
      >
        {selectedAddress?.tag ? (
          <option defaultValue>{selectedAddress?.tag}</option>
        ) : (
          <option defaultValue>{location ? location : "Mumbai"}</option>
        )}
      </select> */}
      {selectedAddress?.tag && location && (
        <p className="locationName" onClick={() => switchTab("locationSelector")}>
          {location
            ? location.length >= 20
              ? location.slice(0, 20) + "..."
              : location
            : "Mumbai"}
        </p>
      )}
    </li>

    // <li className={styles["select-location"]}>
    //   <select
    //     className={styles["form-select"]}
    //     aria-label="Default select example"
    //   >
    //     <option defaultValue>Select Location</option>
    //     <option value="1">Home - Malad West, Mumbai</option>
    //     <option value="2">Two</option>
    //     <option value="3">Three</option>
    //   </select>
    // </li>
  );
};

export default LocationSelection;
