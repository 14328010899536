import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import bottomBarSlice from "./bottomBarSlice";
import chefSlice from "./chefSlice";
import menuSlice from "./menuSlice";
import loginSlice from "./loginSlice";
import cartSlice from "./cartSlice";
import storieSlice from "./storieSlice";
import underconstructionSlice from "./underconstructionslice";
import dishSlice from "./dishSlice";
import referSlice from "./referSlice";
import locationSlice from "./locationSlice";

const store = configureStore({
  reducer: {
    chef: chefSlice.reducer,
    menu: menuSlice.reducer,
    bottomBar: bottomBarSlice.reducer,
    auth: loginSlice.reducer,
    cart: cartSlice.reducer,
    storie: storieSlice.reducer,
    underconstruction: underconstructionSlice.reducer,
    dish: dishSlice.reducer,
    refer: referSlice.reducer,
    location: locationSlice.reducer,
  },
  middleware: [...getDefaultMiddleware(), thunk],
});

export default store;
