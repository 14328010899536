import React, { useEffect, Suspense, useState, useLayoutEffect } from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { setCartData } from "../../store/cartActions";
import { cartAction } from "../../store/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import "./cart.css";
import {
  DELIVERY_CHARGE_PER_KMS,
  EVENING_BREAK_SHIFT_END_TIME,
  EVENING_BREAK_SHIFT_START_TIME,
  EVENING_SHIFT_END_TIME,
  EVENING_SHIFT_START_TIME,
  FREE_DILIVERY_DISTANCE_RANGE,
  MORNING_BREAK_SHIFT_END_TIME,
  MORNING_BREAK_SHIFT_START_TIME,
  MORNING_SHIFT_END_TIME,
  MORNING_SHIFT_START_TIME,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
} from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import { red } from "@material-ui/core/colors";
import ReactGA from "react-ga4";
import { checkout, checkoutUpdate } from "../../store/loginActions";
import { referActions } from "../../store/referSlice";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";
import { loginActions } from "../../store/loginSlice";
import EarlyUsers from "../userprofile/EarlyUsers";
import { BenefitsSkims, EarlyUsersBoxWrapper, PopBenifits } from "../userprofile/styled";
import ReactPixel from 'react-facebook-pixel';

const GST_RATE = 5;
const MAX_ORDER_DISHES = 2;

const Cart = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "cart",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const { cartData, totalCost, isAddressOptionsVisible } = useSelector(
    ({ cart }) => cart
  );
  const chefList = useSelector((state) => state.chef.chefList);
  const addresses = user.addressLine1;
  const dispatch = useDispatch();
  let maxDishesAllowed = user.maxDishes - user.totalDishesBooked;
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const unserviceableModalOnCart = useSelector((state) => state.auth.unserviceableModalOnCart);
  const farAwayModalOnCart = useSelector((state) => state.auth.farAwayModalOnCart);
  const isMenuClicked = useSelector((state) => state.menu.isMenuClicked);
  const delivery_distance = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_distance
  );
  let user_location = useSelector((state) => state.auth.location);
  const delivery_duration = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  );
  let delivery_Time = PREP_TIME + (delivery_duration ? Math.round(delivery_duration / 60) : 10);
  
  const [unAvailable, setUnAvailable] = useState(false);
  
  useEffect(() => {
    dispatch(setCartData(user.userid, user.maxDishes));
  }, [dispatch, user.userid]);

  useEffect(() => {
    let unAvailableDishFound = false;
    cartData.forEach(cartItem => {
      if(unAvailableDishFound) return false;
      chefList.forEach(chef => {
        if(cartItem.chefId == chef._id){
          if(chefStatus(chef) != "online"){
            setUnAvailable(true);
            unAvailableDishFound = true;
            return false;
          }
        }
      });
    });
    if(!unAvailableDishFound) setUnAvailable(false);
  }, [cartData]);

  useLayoutEffect(() => {
    dispatch(cartAction.resetTotalCost());
  }, [dispatch]);

  const chefStatus = (chef) => {
    let isAvailable = "away";
    if(chef.leave){
      isAvailable = "leave"
    } else if(chef.breakShift){
      const now = new Date().getHours();
      if (now >= MORNING_BREAK_SHIFT_START_TIME && now < MORNING_BREAK_SHIFT_END_TIME) {
          isAvailable = "online"
      } else if (now >= EVENING_BREAK_SHIFT_START_TIME && now < EVENING_BREAK_SHIFT_END_TIME) {
          isAvailable = "online"
      }
    } else{
      const now = new Date().getHours();
      if (now >= MORNING_SHIFT_START_TIME && now < MORNING_SHIFT_END_TIME) {
        if(chef.shift){
          isAvailable = "online"
        }
      } else if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
        if(!chef.shift){
          isAvailable = "online"
        }
      }
    }
    return isAvailable;    
  }

  const onProceedToPay = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "checkout" }));
    mixpanel.track("pay-now-clicked");
    ReactPixel.track("InitiateCheckout");
  }

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const showReferView = () => {
    dispatch(bottomBarActions.hideCart());
    dispatch(referActions.onReferClick({ from: "cart" }));
  };

  useEffect(() => {
    dispatch(loginActions.setSelectedOrder(null));
  }, []);

  const getDeliveryFee = () => {
    if (delivery_distance && isAreaServiceable) {
      let deliveryDistanceInKMS = Math.floor(delivery_distance / 1000);
      if (deliveryDistanceInKMS < FREE_DILIVERY_DISTANCE_RANGE) {
        return 0;
      } else {
        let fee = deliveryDistanceInKMS * DELIVERY_CHARGE_PER_KMS;
        if(cartData.length > user.maxDishes){
          cartData.forEach(dish => {
            if(!dish.isAddedOnDiscount){
              fee = fee - dish.deliveryCost;
            }
          });
        }
        return fee > 0 ? fee : 0;
      }
    } else {
      return 0;
    }
  };

  const goToCheckout = (tabName) => {
    //TO-DO: Restriction - Remove first if for 2 dish restriction
    // if (cartData.length <= maxDishesAllowed && user.maxDishes !== 0) {

    let deliveryFee = getDeliveryFee();
    if (user.email) {
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      dispatch(checkoutUpdate(cartData, user, orderType, deliveryFee));
    } else {
      switchTab(tabName);
      // }
    }
    mixpanel.track("pay-later-clicked");
    ReactPixel.track("InitiateCheckout");
  };

  const getGSTAmount = () => {
    // Calculate 5% GST
    return (parseInt(totalCost) * GST_RATE) / 100;
  };

  const getPackagingAmount = () => {
    let packagingFee = 0;
    cartData.forEach(cart => {
      if(cart.isAddedOnDiscount){
        packagingFee = packagingFee + cart.packagingCost;
      }
    });
    return packagingFee;
  };  

  const onBackClick = () => {
    isMenuClicked ? switchTab("menu") : switchTab("chef");
  };
  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name={isAreaServiceable ? delivery_duration ? delivery_Time + " mins to your location" : "Setup location" : "Pre-order"}
            cuisine={isAreaServiceable ? "Delivering from Marol, Andheri East" : "Your location is out of delivery range"}
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="section-food-accordian">
          {!isAreaServiceable && MAX_ORDER_DISHES <= user.totalDishesBooked ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Thank you for pre-ordering from our chefs.</h5>
                <p>Refer your friends to get 3 more dishes!</p>
                <button onClick={() => showReferView()}>
                  <img src={browseLogo} alt="browseLogo" />
                  Refer
                </button>
              </div>
            </div>
          ) : cartData.length > 0 ? (
            <div className="food-accordian">
              <p className="cart-header">Order Details</p>
              <div className="dish-wrapper-list">
                {cartData.map((dish, i) => {
                  return (
                    <>
                      <Dish
                        key={i}
                        id={dish._id}
                        name={dish.name}
                        category={"Starters"}
                        price={dish.price}
                        type={dish.type ? dish.type.toUpperCase() : "VEG"}
                        img={
                          dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""
                        }
                        mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                        description={dish.description}
                        fromCart={true}
                        dish={dish}
                        calculateTotal={true}
                        isFromCart={true}
                        parentComponent="cart"
                        isScheduleActive={true}
                        unAvailable={dish.unavailable}
                        isPriceBandVisible={dish.isAddedOnDiscount}
                        isAddedOnDiscount={dish.isAddedOnDiscount}
                        isFromOrderSummary={false}
                      />
                    </>
                  );
                })}
              </div>
            </div>
          ) : user.totalDishesBooked == 0 && !isAreaServiceable ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Pre-order now, Pay later!</h5>
                <p>
                  You can pre-order 2 dishes from the first 8 chefs now. <br />
                  When we launch, you can pay and schedule your delivery.
                </p>

                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          ) : user.totalDishesBooked > 0 && !isAreaServiceable ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Pre-order now, Pay later!</h5>
                <p>
                  You can pre-order {maxDishesAllowed} more dishes. <br />
                  You can schedule your order and pay when we're live.
                </p>
                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          ) : (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Order Now!</h5>
                <p>
                  You can pre-order {maxDishesAllowed} more dishes. <br />
                  You can schedule your order and pay when we're live.
                </p>
                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          )}
        </section>
      </div>
      {isAreaServiceable && cartData.length != 0 ? (
        <div className="billing-section mx-2">
          <div className="billing-title">Billing Details</div>
          <div className="price-box">
            <div className="items-price items__first">
              <div className="price-title">Items Price</div>
              <div className="price-value"> &#8377; {parseInt(totalCost)} </div>
            </div>
            <div className="items-price items">
              <div className="price-title">{delivery_duration ? "Delivery Fees (for " + Math.floor(delivery_distance / 1000) + " kms)" : "Delivery Fees"}</div>
              <div className="price-value"> &#8377; {getDeliveryFee()} </div>
            </div>
            <div className="items-price items">
              <div className="price-title">Packaging</div>
              <div className="price-value"> &#8377; {getPackagingAmount()} </div>
            </div>
            <div className="items-price items">
              <div className="price-title">GST</div>
              <div className="price-value"> &#8377; {getGSTAmount()} </div>
            </div>
            <div className="items-price items__total">
              <div className="price-title">Total</div>
              <div className="price-value">
                {" "}
                &#8377;{" "}
                {Math.round(totalCost + getGSTAmount() + getDeliveryFee() + getPackagingAmount())}{" "}
              </div>
            </div>
          </div>

          {user.maxDishes == 2 && user.totalDishesBooked < user.maxDishes && (
            <div className="not-serviceble-badge">
              <p>
                You can order {user.maxDishes - user.totalDishesBooked} dishes
                at discounted price.
              </p>
            </div>
          )}

          {!user_location ? (
            <div className="select-address_container">
                <div
                className="add-addres"
                onClick={() => {
                  switchTab("locationSelector");
                  dispatch(bottomBarActions.updateFrom({ from: "cart" }));
                  mixpanel.track("cart-setup-location-clicked");
                }}
                >
                <span>SETUP LOCATION</span>
              </div>
            </div>
          ) :
          isAddressOptionsVisible ? (
            <div className="select-address_container">
              {addresses.length ? (
                <>
                  <div
                    className="add-addres"
                    onClick={() => {
                      switchTab("addressForm");
                      mixpanel.track("add-address-clicked");
                      dispatch(loginActions.setSelectedOrder(null));
                    }}
                  >
                    <span>ADD ADDRESS</span>
                  </div>
                  <div
                    className="select-address"
                    onClick={() => {
                      switchTab("address");
                      mixpanel.track("select-address-clicked");
                    }}
                  >
                    <span>SELECT ADDRESS</span>
                  </div>
                </>
              ) : (
                <div
                  className="add-addres"
                  onClick={() => {
                    switchTab("addressForm");
                    mixpanel.track("add-address-clicked");
                  }}
                >
                  <span>ADD ADDRESS</span>
                </div>
              )}
            </div>
          ) : unAvailable ? (
            <div className="select-address_container">
                <div
                className="add-addres"
                onClick={() => {
                  switchTab("chef");
                  mixpanel.track("chef-unavailable-clicked");
                }}
                >
                <span>Some Chefs are not online</span>
              </div>
            </div>
          ) : (
            <div className="pay-box bottom-box">
              <div className="view-bill">
                <div>&#8377; {Math.round(totalCost + getGSTAmount() + getDeliveryFee() + getPackagingAmount())}{" "} </div>
                <div>
                  <a className="view-bill-link" href="javacsript:void(0)">
                    Total amount
                  </a>
                </div>
              </div>
              <div
                className="pay-bill"
                onClick={onProceedToPay}
              >
                <span>PROCEED TO PAY</span>
              </div>
            </div>
          )}
          {farAwayModalOnCart && 
          <PopBenifits>
            <div className="modal-top-section">
              {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                <img src={CloseIcon} alt="CloseIcon" />
              </div> */}
            </div>
            <EarlyUsersBoxWrapper>
              <div className="BenefitsSkimsTitle">
                <p>
                  You are ordering from a far away location
                </p>
              </div>
              <BenefitsSkims>
                <div className="SkimItem">
                  <img src="./images/true.png" alt="" />
                  <p>This can affect food quality, temprature and consistency.</p>
                </div>
                <div className="SkimItem">
                  <img src="./images/true.png" alt="" />
                  <p>We'll try our best to deliver the best but food always tastes best when hot.</p>
                </div>
              </BenefitsSkims>
              <div className="button-box">
                <a className="btn-login" onClick={() => dispatch(loginActions.setFarAwayModalOnCart(false))}>
                  <span>Got it</span>
                </a>
              </div>
            </EarlyUsersBoxWrapper>
          </PopBenifits>}
        </div>
      ) : (
        user.totalDishesBooked < MAX_ORDER_DISHES &&
        cartData.length != 0 && (
          <div className="billing-section mx-2">
            <div className="billing-title">Billing Details</div>
            <div className="price-box">
              <div className="items-price items__first">
                <div className="price-title">Items Price</div>
                <div className="price-value">
                  {" "}
                  &#8377; {parseInt(totalCost)}{" "}
                </div>
              </div>
              <div className="items-price items">
                <div className="price-title">Delivery Fees</div>
                <div className="price-value"> &#8377; 0 </div>
              </div>
              <div className="items-price items">
                <div className="price-title">Packaging</div>
                <div className="price-value"> &#8377; {getPackagingAmount()} </div>
              </div>
              <div className="items-price items">
                <div className="price-title">GST</div>
                <div className="price-value"> &#8377; {getGSTAmount()} </div>
              </div>
              <div className="items-price items__total">
                <div className="price-title">Total</div>
                <div className="price-value">
                  {" "}
                  &#8377; {Math.round(totalCost + getGSTAmount() + getPackagingAmount())}{" "}
                </div>
              </div>
            </div>
            {/* //TO-DO: Restriction - Remove first part for 2 dish restriction */}
            {user.totalDishesBooked + cartData.length > MAX_ORDER_DISHES ? (
              <div className="cart-option bottom-box" id="cart_option">
                <div className="cart-option-detail pay-box">
                  <a className="menu-link m-auto">
                    <span>
                      You can pre-order up to {maxDishesAllowed} dishes
                    </span>
                  </a>
                </div>
              </div>
            ) : (
              <>
                <div className="not-serviceble-badge">
                  <p>Your location is not serviceable but you can pre-order</p>
                </div>
                <div className="pay-box bottom-box">
                  <div className="view-bill">
                    <div>&#8377; {Math.round(totalCost + getGSTAmount() + getDeliveryFee() + getPackagingAmount())}{" "} </div>
                    <div>
                      <a className="view-bill-link" href="javacsript:void(0)">
                      Total amount
                      </a>
                    </div>
                  </div>
                  <div
                    className="pay-bill"
                    onClick={() => goToCheckout("checkout")}
                  >
                    <span>Pre-order now and Pay Later</span>
                  </div>
                  {unserviceableModalOnCart && 
                  <PopBenifits>
                    <div className="modal-top-section">
                      {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                        <img src={CloseIcon} alt="CloseIcon" />
                      </div> */}
                    </div>
                    <EarlyUsersBoxWrapper>
                      <div className="BenefitsSkimsTitle">
                        <p>
                          Your location is not in delivery range
                        </p>
                      </div>
                      <BenefitsSkims>
                        <div className="SkimItem">
                          <img src="./images/true.png" alt="" />
                          <p>You can pre-book the order now, no payments required.</p>
                        </div>
                        <div className="SkimItem">
                          <img src="./images/true.png" alt="" />
                          <p>When you're closer to Andheri East, you can schedule the booking</p>
                        </div>
                        <div className="SkimItem">
                          <img src="./images/true.png" alt="" />
                          <p>Or wait for some chefs to setup shop near you!</p>
                        </div>
                      </BenefitsSkims>
                      <div className="button-box">
                        <a className="btn-login" onClick={() => dispatch(loginActions.setUnserviceableModalOnCart(false))}>
                          <span>Got it</span>
                        </a>
                      </div>
                    </EarlyUsersBoxWrapper>
                  </PopBenifits>}
                </div>
              </>
            )}
          </div>
        )
      )}
      {((!isAreaServiceable && MAX_ORDER_DISHES <= user.totalDishesBooked) ||
        cartData.length == 0) && <BottomBar />}
      <ToastContainer limit={1} />
    </AppContainer>
  );
};
export default Cart;
