import React from "react";
import { useSelector } from "react-redux";
import "./chef-cards.css";
import { EVENING_BREAK_SHIFT_END_TIME, EVENING_BREAK_SHIFT_START_TIME, EVENING_SHIFT_END_TIME, EVENING_SHIFT_START_TIME, MORNING_BREAK_SHIFT_END_TIME, MORNING_BREAK_SHIFT_START_TIME, MORNING_SHIFT_END_TIME, MORNING_SHIFT_START_TIME, PREP_TIME, WEEK_OFF } from "../../store/constants";

const ChefName = (props) => {
  const delivery_duration = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  );
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const now = new Date().getHours();
  const today = new Date().getDay();
  let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;
  let isMorning = false;
  let isAvailable = "away";
  let offDay = false;
  if(isServiceable){
    if(props.leave){
      isAvailable = "leave"
    } else if(props.breakShift){
      if (now >= MORNING_BREAK_SHIFT_START_TIME && now < MORNING_BREAK_SHIFT_END_TIME) {
          isAvailable = "online";
      } else if (now >= EVENING_BREAK_SHIFT_START_TIME && now < EVENING_BREAK_SHIFT_END_TIME) {
          isAvailable = "online"
      }
    } else{
      if (now >= MORNING_SHIFT_START_TIME && now < MORNING_SHIFT_END_TIME) {
        if(props.shift){
          isAvailable = "online"
        }
      } else if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
        if(!props.shift){
          isAvailable = "online"
        }
      }
    }
  } else {
    isAvailable = "unserviceable";  
  }

  if(now < MORNING_BREAK_SHIFT_END_TIME){
    isMorning = true;
  }

  if((today == WEEK_OFF && now > EVENING_SHIFT_END_TIME) || (today == (WEEK_OFF+1) && now < EVENING_SHIFT_END_TIME)){
    offDay = true;
  }

  return (
    <div className="chef-detail">
      <h4 className="chef-name">Chef {props.name}</h4>
      <div className="chef-status-indicator">
        <div
          className={`
          ${
            offDay ? "chef-away"
            : isAvailable === "online"
              ? "chef-online"
              : isAvailable === "away"
              ? "chef-away"
              : "chef-non-serviciable"
          }
        `}
        />
        <p>
          {offDay ? "Weekly off"
          : isAvailable === "online"
            ? delivery_duration ? `Delivery in ${PREP_TIME + delivery_Time} mins` : "Open now"
            : isAvailable === "away"
            ? `Opens at ${props.breakShift ? isMorning ? "11am" : "7pm"  : props.shift ? "11am" : "7pm"}`
            : isAvailable === "leave"
            ? `Away on leave`
            : "Pre-bookings only"}
        </p>
      </div>
    </div>
  );
};

export default ChefName;