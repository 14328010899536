import React from "react";
import "../components/base.css";
import mixpanel from "mixpanel-browser";


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        mixpanel.track("error-page-shown");
        // You can render any custom fallback UI
        return (
            <body className="error">
                <h1>Something went wrong!</h1>
            </body>
        );
      }
      return this.props.children;
    }
  }
  export default ErrorBoundary;