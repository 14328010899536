import { useDispatch } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "./orderStatus-badge.css";

const OrderStatusBadge = (props) => {
  const dispatch = useDispatch();
  const switchTab = (tabName) => {
    props.orderType == "order-now" ? 
    dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }))
    :dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
  };

  return (
    <div
      className="order_status-badge"
      onClick={() => switchTab("orderSummary")}
    >
      {props.orderType == "order-now" ? (
        <><p>Your Order is in progress</p>
        <span>View</span></>
      ) : (
        <><p>You have a pre-order</p>
        <span>Schedule</span></>
      )}

      
    </div>
  );
};

export default OrderStatusBadge;
