import React, { useState } from "react";
import "./menu-type-filter.css";
import mixpanel from 'mixpanel-browser';

const MenuTypeFilter = ({ setCategoriesdata, allCategorie }) => {
  const [isVegActive, setIsVegActive] = useState(false);
  const [isNonVegActive, setIsNonVegActive] = useState(false);
  const [isEggActive, setIsEggActive] = useState(false);
  const [selected, setSelected] = useState([]);

  const [showInfoBox, setShowInfoBox] = useState(false);

  const handleVegNonVeg = (type) => {
    switch (type) {
      case "veg":
        setIsVegActive(!isVegActive);
        if (!isVegActive) {
          selected.push("veg");
        } else {
          removeItemOnce(selected, "veg");
        }
        break;
      case "non veg":
        setIsNonVegActive(!isNonVegActive);
        if (!isNonVegActive) {
          selected.push("non veg");
        } else {
          removeItemOnce(selected, "non veg");
        }
        break;
      case "egg":
        setIsEggActive(!isEggActive);
        if (!isEggActive) {
          selected.push("egg");
        } else {
          removeItemOnce(selected, "egg");
        }
        break;
      default:
        break;
    }
    const cateArray = allCategorie
      ?.map((categorie) => {
        const dishes = categorie.dishes?.filter(
          (dish) => selected.length == 0 || selected.includes(dish.type)
        );
        return { ...categorie, dishes };
      })
      .filter(({ dishes }) => dishes.length);
    setCategoriesdata(cateArray);
    mixpanel.track("menu-filtered");
  };

  const removeItemOnce = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  return (
    <section className="section-food-type">
      <ul className="food-list">
        <li
          className={`${
            isVegActive ? "Active" : "notactive"
          } food-list-item veg`}
          onClick={() => handleVegNonVeg("veg")}
        >
          <figure>
            <img src="images/icon-veg.svg" alt="veg" loading="lazy" />
            <figcaption>VEG</figcaption>
          </figure>
        </li>
        <li
          className={`${
            isEggActive ? "Active" : "notactive"
          } food-list-item egg`}
          onClick={() => handleVegNonVeg("egg")}
        >
          <figure>
            <img src="images/icon-egg.svg" alt="egg" loading="lazy" />
            <figcaption>EGG</figcaption>
          </figure>
        </li>
        <li
          className={`${
            isNonVegActive ? "Active" : "notactive"
          } food-list-item non-veg`}
          onClick={() => handleVegNonVeg("non veg")}
        >
          <figure>
            <img src="images/icon-non-veg.svg" alt="non-veg" loading="lazy" />
            <figcaption>NON VEG</figcaption>
          </figure>
        </li>
      </ul>
      <p className="pricing-info" onClick={() => {
          setShowInfoBox(!showInfoBox);
          mixpanel.track("how-pricing-works-clicked");
        }}>How pricing works?</p>
      {showInfoBox && (
        <div className="price-info-box">
          <div>
              <h6>Why is there such a huge discount?</h6>
              <p>We want our initial customers to test the food and give us feedback on the overall experience. So, we’re providing dishes at a deep discount in return to your valuable feedback.</p>
          </div>
          <div>
              <h6>How pricing works?</h6>
              <p>Dish prices vary depending on their popularity i.e. how many people have booked the dish.They’re divided into 3 categories:</p>
              <ul type="a">
                <li>- Hot Bun - Most booked dishes at 40-50% off</li>
                <li>- Popular Platter - Dishes catching attention at 50-70% off</li>
                <li>- Hidden Gems - Unexplored delicacies at 70-99% off</li>
              </ul>
          </div>
          <button onClick={() => {
          setShowInfoBox(!showInfoBox);
        }}>Close</button>
        </div>
      )}
    </section>
  );
};

export default MenuTypeFilter;
