import MenuTopBar from "../menu/MenuTopBar";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import DoneIcon from "@material-ui/icons/Done";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import AppContainer from "../util/AppContainer";
import "./order-summary.css";
import { useDispatch, useSelector } from "react-redux";
import Dish from "../menu/Dish";
import { setBookingData } from "../../store/loginActions";
import { useEffect, useState } from "react";
import "../dishBook/dishBook.css";
import "../menu/browse-menu-button.css";
import BottomBar from "../BottomBar";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import InstallApp from "../InstallApp/InstallPWA";
const GST_RATE = 5;

const OrderSummary = () => {
  const deliveryAddress = useSelector((state) => state.auth.selectedAddress);
  const [deliveryEstimatedTime, setDeliveryEstimatedTime] = useState(0);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.auth.orderDetails);
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("chef");
  };

  var add_minutes =  function (dt, minutes) {
    return new Date(dt.getTime() + minutes*60000);
  }

  useEffect(() => {
    let endTime = add_minutes(new Date(orderDetails.createdAt), orderDetails.deliveryTime);
    setDeliveryEstimatedTime(endTime.getHours() + " " + endTime.getMinutes());
    mixpanel.track("order-summary");
  }, []);

  var totalCost = 0;

  const getGSTAmount = () => {
    // Calculate 5% GST
    return (parseInt(totalCost) * GST_RATE) / 100;
  };
  return (
    <AppContainer>
      <div className="orderSummary_wrapper">
        <div className="orderSummary_top">
          <MenuTopBar
            name="Order Summary"
            notMenu={true}
            onBackClick={onBackClick}
          />
          <div className="orderSummary_icon">
            <ReceiptIcon />
          </div>
          <div className="orderSummary_content">
            <h5>ORDER SUMMARY</h5>
          </div>
        </div>

        {orderDetails?.orderType == "order-now" && (
          <>
            <div className="order-info">
              <div className="order-info_left">
                <span>Delivery Time</span>
                <div className="delivery-info">
                  <div className="order-info_icon">
                    <AccessTimeOutlinedIcon />
                  </div>
                  {/* TO:DO: Replace with deliveryEstimatedTime with proper formatting and content */}
                  <div className="order-info_time">
                    <span className="order-info_span">{orderDetails.deliveryTime + " mins"}</span>
                  </div>
                </div>
              </div>
              <div className="order-info_right">
                <span>Phone Number</span>
                <div className="delivery-info" onclick="window.open('tel:+918655861798');">
                  <div className="order-info_icon">
                    <LocalPhoneIcon />
                  </div>
                  <div className="order-info_time">
                    <span className="order-info_span">{"+918655861798"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="status_badge">
              <h6>PREPARING</h6>
              <DoneIcon />
            </div>
          </>
        )}
        {/* <div className="ordered-dishes_container">
        <div className="ordered_dish">
          <div className="dish_name"></div>
        </div>
    </div> */}
        <h6 className="order-details">Order Details</h6>
        <div className="ordered-dishes_container">
          <section className="section-food-accordian">
            {orderDetails && orderDetails.bookedDishes && (
              <div className="food-accordian">
                <div className="dish-wrapper-list">
                  {orderDetails.bookedDishes.map((bookedDish, i) => {
                    let dish = bookedDish.dish;
                    let isPriceBandVisible =
                      user.maxDishes == 2 && bookedDish.isAddedOnDiscount;
                    bookedDish.isAddedOnDiscount || orderDetails.preOrdered
                      ? (totalCost += bookedDish.dealPrice)
                      : (totalCost += bookedDish.price);
                    return (
                      <>
                        {dish.price && (
                          <Dish
                            key={i}
                            id={dish._id}
                            name={dish.name}
                            category={"Starters"}
                            price={bookedDish.price}
                            type={dish.type ? dish.type.toUpperCase() : "VEG"}
                            img={
                              !orderDetails.preOrdered && dish.img //Temp change: pre-ordered dishes will not have images, change after 1st set of pre-launch users have booked
                                ? S3_MENU_THUMBNAIL_FOLDER + dish.img
                                : ""
                            }
                            description={dish.description}
                            fromCart={false}
                            dish={dish}
                            calculateTotal={true}
                            isFromCart={false}
                            dealPrice={bookedDish.dealPrice}
                            parentComponent="dishBook"
                            isBooked={true}
                            isScheduleActive={false}
                            isPriceBandVisible={isPriceBandVisible}
                            isAddedOnDiscount={bookedDish.isAddedOnDiscount}
                            isFromOrderSummary={true}
                            from="orderSummary"
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </section>
        </div>
        <div className="delivery-address">
          <div className="delivery-address_top">
            <span className="delivery-address_span">Delivery Address</span>
            <LocationOnIcon />
          </div>
          <p className="delivery-address_content">
            {deliveryAddress?.location?.locationName}
          </p>
        </div>
      </div>
      
      {orderDetails.paymentMode ? 
      <div className="">
        <div className="billing-title">Payment Mode - {orderDetails.paymentMode}</div>
      </div>
      : <></>
      }

      <div className="billing-section ">
        <div className="billing-title">Billing Details</div>
        <div className="price-box">
          <div className="items-price items__first">
            <div className="price-title">Items Price</div>
            <div className="price-value">
              {" "}
              &#8377;
              {parseInt(orderDetails.totalCost)}
            </div>
          </div>
          <div className="items-price items">
            <div className="price-title">Delivery Fees</div>
            <div className="price-value">
              {" "}
              &#8377; {orderDetails.deliveryFee}{" "}
            </div>
          </div>
          <div className="items-price items">
            <div className="price-title">Packaging</div>
            <div className="price-value"> &#8377; {orderDetails.packagingFee} </div>
          </div>
          <div className="items-price items">
            <div className="price-title">GST</div>
            <div className="price-value">
              &#8377;
              {orderDetails.gstFee}
            </div>
          </div>
          <div className="items-price items__total">
            <div className="price-title">Total</div>
            <div className="price-value">
              {" "}
              &#8377;
              {orderDetails.billingAmount}
            </div>
          </div>
        </div>
      </div>
      <InstallApp />
      <BottomBar />
    </AppContainer>
  );
};

export default OrderSummary;
