import { initializeApp } from "@firebase/app";
import { getMessaging } from "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAETqKY7DDK6ag0j2f7TlHcXlAVT0jVT2c",
  authDomain: "chef-notification-4fb9a.firebaseapp.com",
  projectId: "chef-notification-4fb9a",
  storageBucket: "chef-notification-4fb9a.appspot.com",
  messagingSenderId: "836277542853",
  appId: "1:836277542853:web:a265728627da2baa5bb5ac",
  measurementId: "G-FD0BH0DCXP",
};

export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
