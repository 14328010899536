export const getUniqueArray = (array) => {
  const uniqueIds = [];
  return array.filter((obj) => {
    if (!uniqueIds.includes(obj._id)) {
      uniqueIds.push(obj._id);
      return true;
    }
    return false;
  });
};
