import React, { useEffect, useState } from "react";
import "./menu-categories.css";
import "../base.css";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { setStoryData } from "../../store/storieAction";
import { cartAction } from "../../store/cartSlice";
import { addDish, removeDish, saveToken } from "../../store/cartActions";
import { toast } from "react-toastify";
import { fetchDishBand } from "../../store/menuActions";
import { updateDishPrice } from "../../store/loginActions";
import mixpanel from "mixpanel-browser";
import Joyride from "react-joyride";
import { menuActions } from "../../store/menuSlice";
import { chefActions } from "../../store/chefSlice";
import DishCheckPrice from "./DishCheckPrice";
import ReactPixel from "react-facebook-pixel";

const steps = [
  {
    title: "Swipe to browse chefs",
    content: "Stop scrolling endlessly, these chefs deserve your attention!",
    spotlightPadding: 0,
    target: ".check-price-btn",
    disableBeacon: true,
  },
];

const Dish = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const dish = useSelector(({ dish }) => dish);
  const loggedIn = user.phonenumber ? true : false;
  const { menuCartData, cartData } = useSelector(({ cart }) => cart);
  const demoForCheckPrice = useSelector(
    (state) => state.menu.demoForCheckPrice
  );
  const setDemoForCheckPrice = useSelector(
    (state) => state.menu.setDemoForCheckPrice
  );

  const [showAdded, setShowAdded] = useState(
    menuCartData.map((cart) => cart.dishId)?.includes(props.id)
  );

  const [showAdd, setShowAdd] = useState(
    loggedIn
      ? !showAdded &&
        user.phonenumber &&
        user.maxDishes == 2 &&
        user.totalDishesBooked < user.maxDishes
        ? user.dishIdsChecked?.includes(props.id)
        : !showAdded
      : false
  ); //TO-DO: Restriction - remove dishIds check to remove checkprice
  const [showSchedule, setShowSchedule] = useState(props.isBooked);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [dishBand, setDishBand] = useState({});
  const [isAddDish, setIsAddDish] = useState(false);
  const cart = useSelector((state) => state.bottomBar.cart);
  const storie = useSelector((state) => state.bottomBar.storie);
  const dishes = useSelector((state) => state.bottomBar.dishes);

  const onAddDish = async (dishID) => {
    if (!user.userid) {
      dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    } else {
      let totalDiscountedDishesAdded = 0;
      cartData.forEach((cart) => {
        if (cart.isAddedOnDiscount == true) {
          totalDiscountedDishesAdded += 1;
        }
      });

      let isDiscountAllowed =
        user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;

      let isAddedOnDiscount =
        isDiscountAllowed && user.maxDishes !== 0 ? true : false;
      dispatch(
        addDish({
          cartPayload: {
            userId: user.userid,
            dishId: dishID,
            dealPrice: currentPrice,
            isAddedOnDiscount: isAddedOnDiscount,
            price: props.dish.price,
          },
          dish: props.dish,
          maxDishes: user.maxDishes,
        })
      );
      setShowAdd(false);
      setShowAdded(true);
      setIsAddDish(true);
      if (props.setShowAddedItemPopup) {
        props.setShowAddedItemPopup(true);
      }
    }
    mixpanel.track("added-to-cart");
    ReactPixel.track("AddToCart");
  };

  const onRemoveDish = async (
    dishId,
    setShowAdd,
    setShowAdded,
    cost,
    ingredientCost,
    dishBand,
    totalPortionAdded
  ) => {
    if (!props.fromCart && totalPortionAdded === 1) {
      setShowAdd(true);
      setShowAdded(false);
    }
    setIsAddDish(false);
    dispatch(
      removeDish({
        userId: user.userid,
        dishId: dishId,
        isActive: false,
        maxDishes: user.maxDishes,
      })
    );
    mixpanel.track("dish-removed");
  };
  const onDishStorieClick = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "storie" }));
    dispatch(
      setStoryData(
        [{ img: props.mainImg, name: props.name, cuisine: props?.cuisine }],
        props.from
          ? props.from
          : props.fromCart
          ? "cart"
          : dishes
          ? "dishes"
          : "menu"
      )
    );
  };

  const showOneDishValidation = () => {
    toast.dismiss();
    toast.info("You can only add one portion per dish", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
    mixpanel.track("dish-portion-increased");
  };

  const updateDish = (
    dishId,
    setShowAdd,
    cost,
    ingredientCost,
    fuelCost,
    dishBand
  ) => {
    const isRemovedDish = false;
    dispatch(
      updateDishPrice(
        cost,
        ingredientCost,
        fuelCost,
        dishBand,
        setCurrentPrice,
        false,
        dishId,
        isRemovedDish,
        props.fromCart,
        user.maxDishes,
        user.totalDishesBooked
      )
    );
    props.onCheckPrice(dishId, setShowAdd);
    mixpanel.track("check-price-clicked");
  };

  const getTextForBand = (band) => {
    switch (band) {
      case "HIDDEN_GEM":
        return "Hidden Gems: 70-99% off";
      case "MIDDLE":
        return "Popular Platter: 50-70% off";
      case "TRENDING":
        return "Hot Buns: 40-50% off";
      case "NORMAL":
        return "No discount";
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchDishBand(props.id, setDishBand));
  }, [dispatch]);

  useEffect(() => {
    if (dishBand.band && (showAdd || showAdded)) {
      dispatch(
        updateDishPrice(
          props.price,
          props.dish.ingredientCost,
          props.dish.fuelCost,
          dishBand.band,
          setCurrentPrice,
          props.calculateTotal,
          props.id,
          false,
          props.isFromCart ? props.isFromCart : showAdded,
          user.maxDishes,
          user.totalDishesBooked
        )
      );
    }
  }, [dispatch, showAdd, showAdded, dishBand]);

  const getDealPrice = () => {
    if (props.fromCart) {
      const index = cartData.findIndex((x) => x._id === props.id);
      return index !== -1 && cartData[index].dealPrice
        ? parseInt(cartData[index].dealPrice)
        : parseInt(currentPrice);
    }
    return parseInt(currentPrice);
  };

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    console.log("action", action);
    if (action == "close") dispatch(menuActions.setDemoForCheckPrice());
  };

  return (
    <div className="dish-wrapper-list-item">
      {/* {!demoForCheckPrice && <Joyride steps={steps}
        callback={handleJoyrideCallback}
        disableOverlay={false}
        hideBackButton = {true}
        disableScrolling = {true}
        styles={{
          options: {
            arrowColor:"#F8EDD5",
            primaryColor: "#e8814d",
            zIndex: 10000,
            backgroundColor: '#F8EDD5',
            overlayColor: 'rgba(0, 0, 0, 0)',
          },
          tooltipTitle: {
            fontSize: 16,
            textAlign : 'left',
            paddingLeft : '10px',
            color : '#670000'
          },
          tooltipContent: {
            fontSize: 13,
            padding: '10px 10px',
            textAlign : 'left',
            fontWeight : 300,
            color : '#670000'
          },
          buttonNext : {
            fontSize : '13px'
          }
        }}
      />} */}
      <div className={props.unAvailable ? "media fade4" : "media"}>
        <div className="media-image">
          <ul className="food-list">
            {props.type === "VEG".toLowerCase() && (
              <li className="food-list-item veg saff">
                <figure>
                  <img src="images/icon-veg.svg" alt="veg" loading="lazy" />
                  <figcaption>{props.type}</figcaption>
                </figure>
              </li>
            )}
            {/* {props.saff === true && <li className="food-list-item saff">
              <figure>
                <img src="images/icon-veg.svg" alt="veg" loading="lazy" />
                <figcaption>{props.type}</figcaption>
              </figure>
            </li>} */}
            {props.type === "EGG".toLowerCase() && (
              <li className="food-list-item egg saff">
                <figure>
                  <img src="images/icon-egg.svg" alt="egg" loading="lazy" />
                  <figcaption>{props.type}</figcaption>
                </figure>
              </li>
            )}
            {props.type === "NON VEG".toLowerCase() && (
              <li className="food-list-item non-veg saff">
                <figure>
                  <img
                    src="images/icon-non-veg.svg"
                    alt="non-veg"
                    loading="lazy"
                  />
                  <figcaption>{props.type}</figcaption>
                </figure>
              </li>
            )}
          </ul>
        </div>
        <div className="media-body">
          <div className="row m-0">
            <div className="col pl-0 left">
              <div className="media-dish-details">
                <div className="main-details">
                  <div className="dish-check-price-img-missing">
                    <h4 className="media-dish-title">{props.name}</h4>
                    {!props.img && (
                      <div className="right disk-check-price-position">
                        <DishCheckPrice
                          showAdd={showAdd}
                          setShowAdd={setShowAdd}
                          showAdded={showAdded}
                          setShowAdded={setShowAdded}
                          showSchedule={showSchedule}
                          onAddDish={onAddDish}
                          onRemoveDish={onRemoveDish}
                          updateDish={updateDish}
                          showOneDishValidation={showOneDishValidation}
                          dishBand={dishBand}
                          dish={props}
                          menuCartData={menuCartData}
                        />
                      </div>
                    )}
                  </div>
                  {props.saff === true ? (
                    <div className="media-dish-price">
                      <span className="currency">
                        {((showAdd || showAdded) &&
                          props.isAddedOnDiscount &&
                          user.maxDishes == 2 &&
                          user.totalDishesBooked < user.maxDishes) ||
                        (props.isAddedOnDiscount && props.isFromOrderSummary)
                          ? "Current Price: "
                          : "Original Price: "}
                        &#8377;
                      </span>
                      <span
                        className={
                          (dish.dishBandLoaded &&
                            (showAdd || showAdded) &&
                            props.isAddedOnDiscount &&
                            user.maxDishes == 2 &&
                            user.totalDishesBooked < user.maxDishes) ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                            ? "price strike"
                            : "price"
                        }
                      >
                        {props.price}
                      </span>
                      <span
                        className={
                          (dish.dishBandLoaded &&
                            (showAdd || showAdded) &&
                            user.maxDishes == 2 &&
                            props.isAddedOnDiscount &&
                            user.totalDishesBooked < user.maxDishes) ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                            ? "price"
                            : "hide"
                        }
                      >
                        {props.dealPrice
                          ? parseInt(props.dealPrice)
                          : getDealPrice()}
                      </span>
                    </div>
                  ) : (
                    <div className="media-dish-price">
                      <span className="currency">
                        {((showAdd || showAdded) &&
                          user.maxDishes == 2 &&
                          props.isAddedOnDiscount &&
                          user.totalDishesBooked < user.maxDishes) ||
                        (props.isAddedOnDiscount && props.isFromOrderSummary)
                          ? "Current Price: "
                          : "Original Price: "}
                        &#8377;
                      </span>
                      <span
                        className={
                          (dish.dishBandLoaded &&
                            (showAdd || showAdded) &&
                            user.maxDishes == 2 &&
                            props.isAddedOnDiscount &&
                            user.totalDishesBooked < user.maxDishes) ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                            ? "price strike"
                            : "price"
                        }
                      >
                        {props.price}
                      </span>
                      <span
                        className={
                          (dish.dishBandLoaded &&
                            (showAdd || showAdded) &&
                            user.maxDishes == 2 &&
                            props.isAddedOnDiscount &&
                            user.totalDishesBooked < user.maxDishes) ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                            ? "price"
                            : "hide"
                        }
                      >
                        {props.dealPrice
                          ? parseInt(props.dealPrice)
                          : getDealPrice()}
                      </span>
                    </div>
                  )}
                </div>
                <div className="media-dish-description">
                  <p>{props.description}</p>
                </div>
                {props.isPriceBandVisible && dish.dishBandLoaded ? (
                  <h6 className="media-dish-cap orange-text">
                    {showAdded || showAdd
                      ? "Voila! You got " +
                        Math.floor(
                          ((props.price -
                            (props.dealPrice
                              ? parseInt(props.dealPrice)
                              : getDealPrice())) *
                            100) /
                            props.price
                        ) +
                        "% off"
                      : getTextForBand(dishBand.band)}
                  </h6>
                ) : (
                  ""
                )}
                {props.isPriceBandVisible && dish.dishBandLoaded ? (
                  <h6 className="media-dish-cap orange-text">
                    {Math.round(dishBand.percentBookedInBand)}% Offer claimed
                    <progress
                      className="band-progress"
                      value={Math.round(dishBand.percentBookedInBand)}
                      max="100"
                    ></progress>
                  </h6>
                ) : (
                  ""
                )}
              </div>
            </div>
            {props.img && (
              <div
                className={props.img ? "col p-0 right" : "col p-0 right flex"}
              >
                <div className="media-dish-add">
                  {props.img ? (
                    <figure onClick={onDishStorieClick} className="dish-figure">
                      <span className="img-ring-red">
                        <img src={props.img} alt="profile image" />
                      </span>
                    </figure>
                  ) : (
                    <></>
                  )}
                  <DishCheckPrice
                    showAdd={showAdd}
                    setShowAdd={setShowAdd}
                    showAdded={showAdded}
                    setShowAdded={setShowAdded}
                    showSchedule={showSchedule}
                    onAddDish={onAddDish}
                    onRemoveDish={onRemoveDish}
                    updateDish={updateDish}
                    showOneDishValidation={showOneDishValidation}
                    dishBand={dishBand}
                    dish={props}
                    menuCartData={menuCartData}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.saff === true && (
        <div>
          <div className="food-panner">
            <li className="food-list-item foods">
              <figure>
                <img src={props.dish.chefImg} alt="veg" loading="lazy" />
              </figure>
            </li>
            <div className="food-dish">
              <h4 className="media-dish-title">Chef {props.dish.chefName}</h4>
              <h5 className="media-dish-title2">
                {props.category.cuisine} Cuisne Specialist
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dish;
